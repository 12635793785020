import React from "react";
import Layout from "../components/layout";
import { useLanguage } from "../languageContext";
import { Fade } from "react-reveal";

const Thankyou = () => {
  const currentLang = useLanguage();
  return (
    <Layout isNavWhite={true} hideMap hideFAQ>
      <div className="w-90-wrapper">
        <div className="h-80 thank-you-page">
          <Fade duration={1000}>
            <h2 className={`page-heading titlecase-text `}>
              <b className={`color-${currentLang ? currentLang : "en"}`}>
                Thank You
              </b>{" "}
              for Submitting !
            </h2>

            <h3 className="title">we will get back to you shortly</h3>
          </Fade>
        </div>
      </div>
    </Layout>
  );
};

export default Thankyou;
